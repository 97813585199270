* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%; 
}

body {
  display: flex;
  min-height: 100vh;
  line-height: 1;
  font-family: $font-family;
  font-size: 1.4rem;
  color: $font-color;
  flex-direction: column;
}

a {   
  color: $green;
  text-decoration: none;
}

.mobile-hidden {
  display: none !important;
  
  @include desktop {
    display: flex !important;
  }
}

.desktop-hidden {
  display: flex !important;

  @include desktop {
    display: none !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.alert-success {
  color: $green;
  float: right;
  transition: all 1s ease-in-out;
  opacity: 0;
  
  &.show {
    opacity: 1;
  }
}

