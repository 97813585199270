.form {
  background-color: $white;
  margin-left: -1rem;
  margin-right: -1rem;
  
  @include element('title') {
    background-color: $blue;
    color: $white;
    font-size: 1.6rem;
    padding: 2.6rem 2rem 2rem;
    font-weight: 100;
  }

  @include element('body') {
    padding: 2rem;
  }

  @include element('button') {
    background-color: $blue;
    color: $white;
    border: 0;
    border-radius: .3rem;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1;
    box-shadow: inset 0 -0.2rem 0 rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    transition: all .5s ease;
    
    &:hover {
      cursor: pointer;
      background-color: lighten($blue, 20%);
    }
  }
  
  @include desktop {
    @include element('button') {
      width: 33%;
    }
  }
}

// overrides
.mdl-textfield {
  width: 100%;
}

.mdl-textfield__label {
  font-size: 1.4rem;
}
