.click-slider {
  padding: 6rem 0;

  .container {
    display: flex;
    flex-direction: column;
  }

  .card + .card {
    margin-top: 3rem;
  }

  @include element('images') {
    display: flex;

    @include element('image') {
      width: 50%;
      text-align: center;

      img {
        width: 100%;
      }

      @include element('title') {
        color: $green;
        margin-top: 1rem;
        text-align: center;
      }
    }
  }

  .card img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .card__image {
    text-align: center;
  }

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .container {
      flex-direction: row;
    }

    .half {
      position: relative;
      background-color: $white;

      &:first-child {
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .card__title {
          &:hover {
            transform: scale(1);
          }
        }
        .card__text {
          width: 40rem;
          align-self: center;
        }
      }

      &:last-child {
        padding-left: 5rem;
        border-left: 1px solid $blue-light;
        z-index: 20;
      }
    }

    @include element('images') {
      flex-wrap: wrap;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      @include element('image') {
        margin-bottom: 3rem;
        height: 16.4rem;
        transition: all 1s ease;

        img {
          width: 100%;
        }
      }
    }

    .click-slider__images__image.selected {
      transform: scale(1.2);
    }
  }
}
