.contact {
  padding: 6rem 0;
  background-color: $blue-light;

  .container {
    display: flex;
    flex-direction: column;
  }

  @include element('title') {
    font-size: 2.4rem;
    font-weight: 100;
    text-align: center;
    color: $white;
    margin-bottom: 6rem;
  }

  @include element('map') {
    margin-left: -1rem;
    margin-right: -1rem;

    img {
      display: block;
      width: 100%;
    }
  }

  @include element('info') {
    color: $white;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    line-height: 1.2;
    list-style-type: none;

    a {
      color: $white;
    }
  }

  @include desktop {
    .container {
      flex-direction: row;
    }

    .half {
      &:first-child {
        padding-right: 5rem;
        border-right: 1px solid $white;
      }
      
      &:last-child {
        padding-left: 5rem;
      }
    }
    
    @include element('info') {
      text-align: right;
      margin-bottom: 0;
    }
  }
}