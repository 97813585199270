.services {
  background-color: $bg-color;
  padding: 6rem 0 1.5rem;

  @include element('item') {
    display: flex;
    padding-bottom: 5rem;
    justify-content: space-around;
    flex-direction: column-reverse;
  }

  @include element('image') {
    border: 1px solid $gray-light;
    margin-bottom: 2.5rem;
    img {
      display: block;
      width: 100%;
    }
  }

  @include desktop {

    @include element('item') {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 4rem;
      margin-bottom: 5rem;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      .card,
      .services__image {
        width: 50%;
      }

      .card {
        padding-top: 3rem;
        padding-left: 6.3rem;
      }
    }
  }
}