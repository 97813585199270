.error {
  flex: 1;
  @include element('title') {
    color: $green;
    font-size: 3.2rem;
    position: relative;
    text-align: center;
    font-weight: 100;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 4rem;
      height: .1rem;
      background-color: $blue;
      transform: translateX(-50%);
    }
  }
}