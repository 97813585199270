.header {
  padding: 1.5rem 0;
  box-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.09);
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
  }

  @include element('logo') {
    img {
      width: 22.1rem;
      height: 6.7rem;
    }

    @include desktop {
      margin-left: 3.2rem;
      margin-top: .1rem;
    }
  }

  @include element('hamburger') {
    border: none;
    background-color: transparent;
    color: $blue;
    line-height: 1;
    outline: none;

    @include desktop {
      display: none;
    }
  }

  @include element('menu') {
    font-size: 1.4rem;
    background-color: $white;
    display: none;

    @include element('item') {
      color: $green;
      padding: 1rem 0;
      font-size: 2rem;
      position: relative;

      &:first-child {
        margin-right: 3rem !important;
        font-size: 1.4rem;        
        b {
          font-size: 1.3rem; 
          margin-left: .3rem;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 2.3rem;
        right: 0;
        left: 0;
        height: .1rem;
        background-color: transparent;
      }

      &:not(:first-child):hover:after {
        background-color: $blue;
      }
    }

    @include desktop {
      display: flex;
      margin-right: 2.8rem;
      
      @include element('item') {
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 3.4rem;
        }
      }
      
    }
  }
}

.mobile-menu {
  width: 70vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include element('menu') {
    display: flex;
    flex-direction: column;
  }

  @include element('item') {
    padding-left: 2rem;
    font-size: 2.5rem;
    margin-bottom: 3rem;

    &:first-child {
      align-self: flex-end;
      margin-top: 2rem;
      margin-right: 2rem;
      margin-bottom: 4rem;
      color: $blue;
      background-color: transparent;
      border: 0;
      outline: none;
    }
  }

  @include element('logo') {
    text-align: center;
    padding-bottom: 2rem;
    
    img {
      width: 66%;
    }
  }

  @include desktop {
    display: none;
  }
}

.overlay {
  background-color: $black;
  opacity: .7;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 90;
}


