.about {
  flex: 1;

  @include element('title') {
    color: $green;
    font-size: 3.2rem;
    position: relative;
    text-align: center;
    font-weight: 100;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 4rem;
      height: .1rem;
      background-color: $blue;
      transform: translateX(-50%);
    }
  }

  .about__header__image {
    height: 6rem;
    overflow: hidden;
    margin-bottom: 6rem;

    img {
      width: 100%;
      display: block;
    }
  }

  @include element('body') {
    text-align: center;
    
    p {
      line-height: 2;
      margin-bottom: 3rem;
      font-size: 1.6rem;
    }
  }
  
  @include desktop {
    @include element('body') {
      text-align: left;
      img {
        float: left;
        margin-right: 2rem;
      }

      p {
        line-height: 2;
        margin-bottom: 3rem;
        font-size: 1.6rem;
      }
    }
  }
}