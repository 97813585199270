.banner {
  background-color: $gray-light;
}

.slides {
  display: flex;
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 3s ease-in-out;
    opacity: 0;
    z-index: 10;
  }

  .active {
    opacity: 1;
    // z-index: 20;
  }
}