// colors
$green: #47b679;
$blue: #00b2ff;
$blue-light: #8ad2f2;

$font-color: #515151;
$bg-color: #f5fcff;

$gray-light: #f9f9f9;
$white: #fff;
$black: #000;

// fonts
$font-family: 'open_sansregular',sans-serif;
$font-family-bold: 'open_sansbold',sans-serif;

// breakpoints
$bp-desktop: 960px;
$bp-tablet: 601px;

$desktop-query: '(min-width: #{$bp-desktop})';
$just-tablet-query: '(min-device-width: #{$bp-tablet}) and (max-width: #{$bp-desktop})'; // only tablet
$tablet-query: '(min-device-width: #{$bp-tablet})'; // tablet to infinite

@mixin desktop {
  @media only screen and #{$desktop-query} {
    @content;
  }
}

@mixin tablet {
  @media only screen and #{$tablet-query} {
    @content;
  }
}

