
// the BEM modifier() mixin
@mixin modifier($name) {
  @at-root {
    // '&' is a double-wrapped list
    $selector: nth(&, 1);
    // direct parent will be the last item in that list
    $direct-parent: nth($selector, length($selector));
    // modifier should have all properties of parent
    #{$direct-parent}--#{$name} { @extend #{$direct-parent}; }
    // '@content' will be in a nested selector however, if that is the context
    #{&}--#{$name} { @content; }
  }
}

// a BEM element() mixin--as has been seen elsewhere
@mixin element($name) {
  @at-root {
    #{&}__#{$name} {
      @content;
    }
  }
}

@mixin desktop {
  @media only screen and #{$desktop-query} {
    @content;
  }
}

@mixin tablet {
  @media only screen and #{$tablet-query} {
    @content;
  }
}

