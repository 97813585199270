.footer {
  text-align: center;
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;

  @include element('logo') {
    margin-bottom: 1.5rem;

    img {
      width: 8.7rem;
      height: 11.2rem;
    }
  }

  @include element('menu') {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    a:hover {
      color: $blue;
    }

    @include element('item') {
      color: $green;
      margin-bottom: .3rem;

      &:not(:last-child) {
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: .1rem;
          background-color: $green;
          content: '';
        }
      }

      .icon-logo-up3 {
        line-height: 1;
        position: relative;

        &:before {
          position: absolute;
          top: -0.9rem;
          right: -1.9rem;
          width: 1rem;
          height: 1rem;
          font-size: 3rem !important;
        }

        &:hover {
          color: $blue;
        }
      }
    }

    @include desktop {
      flex-direction: row;
      .icon-logo-up3 {
        line-height: 1;
        position: relative;

        &:before {
          position: absolute;
          top: -2.2rem;
          right: -5.4rem;
          width: 1rem;
          height: 1rem;
          font-size: 3rem !important;
        }
      }
      @include element('item') {
        padding-right: 1rem;
        margin-right: .5rem;
      }
    }
  }
}
