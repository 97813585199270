.card {
  display: flex;
  flex-direction: column;

  @include modifier('center') {
    text-align: center;
  }

  @include element('title') {
    color: $green;
    font-size: 3.2rem;
    font-weight: 100;
    margin-bottom: 1.2rem;
    padding-bottom: 1rem;
    position: relative;
    transition: all .3s ease;

    &:hover {
      transform: scale(1.2);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 4rem;
      height: .1rem;
      background-color: $blue;
    }

    @include modifier('small') {
      font-size: 2.5rem;
      transform-origin: 5% 50%;
    }

    @include modifier('icon') {
      &:before {
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        display: inline-block;
        background-image: url('/img/icon-waterdrop.svg');
        margin-right: 1rem;
      }
    }

    @include modifier('underline-center') {
      transform-origin: 50% 50%;
      &:after {
        transform: translateX(-50%);
        left: 50%;
      }

    }
  }

  @include element('text') {
    line-height: 2.2rem;
  }

  @include element('list') {
    list-style: none;
    padding-left: 1rem;
    line-height: 2.6rem;

    li:before {
      // @extend %icon-class;
      content: url('/img/icon-waterdrop.svg');
      color: $blue;
      padding-right: 1rem;
      width: 14px;
      display: inline-block;
    }
  }
}