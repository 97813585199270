.container {
  max-width: 116.4rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.divider {
  height: 45rem;
  width: .1rem;
  margin: 0 3rem;
  
  @include modifier('blue') {
    background-color: $blue-light;
  }
  @include modifier('white') {
    background-color: $white; 
  }
}

.half {
  width: 100%;
  
  @include desktop {
    width: 50%;
  }
}

