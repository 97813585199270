.three-columns {
  padding: 7rem 0 0;
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @include desktop {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 6rem;
    }
  }
}

.butterfly {
  text-align: center;
  overflow: hidden;
  max-height: 13rem;
  transition: all 2s ease-in-out;
  padding-bottom: 7rem;
  //display: none;

  &.up {
    max-height: 100rem;
  }

  img {
    margin-top: 4rem;
    width: 20rem;
  }

  @include desktop {

    img {
      margin-top: 0;
      //width: 39.5rem;
      width: 39.5rem;
    }
  }
}

.butterfly__caption {
  max-width: 96rem;
  margin: 2rem auto 0;
  padding: 0 1rem;
  //text-align: left;

  p:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.three-columns {
  .card {
    width: 95%;
    padding: 0 2rem;

    @include desktop {
      width: 28.6%;
    }
  }

  .card + .card {
    margin-top: 4rem;

    @include desktop {
      margin-top: 0;
    }
  }

}
