.draromas {
  color: $green;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: center;
  line-height: 1;
  padding: 6rem 0;
  background-image: url('/img/image-social-bg.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .draromaslogo {
    padding-bottom: 20px;
    img {
      max-width: 30%;
    }
  }

  .draromastxt {
    a {
      color: $blue;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  @include desktop {
    flex-direction: column;
    .draromaslogo {
      img {
        max-width: 10%;
      }
    }

    @include element('links') {
      margin-top: 0;
      margin-left: 2rem;

      a:hover {
        color: $blue;
      }

      a:before {
        font-size: inherit;
      }
    }
  }
}

.social {
  color: $green;
  font-size: 2.8rem;
  font-weight: 100;
  text-align: center;
  line-height: 1;
  padding: 6.5rem 0;
  background-image: url('/img/image-social-bg.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include element('links') {
    margin-top: 2rem;
  }

  @include desktop {
    flex-direction: row;

    @include element('links') {
      margin-top: 0;
      margin-left: 2rem;

      a:hover {
        color: $blue;
      }

      a:before {
        font-size: inherit;
      }
    }
  }
}
